import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import { CustomDatePipe } from '../../../pipes/custom-date.pipe';
import { AuthService } from '../../../services/auth.service';
import { WaterMeterService } from '../../../services/water-meter.service';
import { DropdownComponent } from '../../utilities/dropdowns/dropdown/dropdown.component';
import { IconComponent } from '../../utilities/icon/icon.component';

@Component({
  selector: 'aup-header',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    IconComponent,
    FlowbiteMarkDirective,
    RouterLink,
    DropdownComponent,
    DatePipe,
    CustomDatePipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  imageError = signal(false);

  constructor(
    protected authService: AuthService,
    protected translate: TranslateService,
    protected wmService: WaterMeterService,
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe({
      next: (user) => {
        if (user?.settings.language) this.translate.use(user.settings.language);
      },
    });
    return;
  }

  public logout(): void {
    this.authService.logout();
  }

  public async changeLocalization(lang: string) {
    this.translate.use(lang);
    await lastValueFrom(this.authService.updateDefaultLanguage(lang));
  }

  setImageError(isError: boolean): void {
    this.imageError.set(isError);
  }

  get calculatedDdOffset(): number {
    return window.innerWidth < 863 ? 16 : 32;
  }
}
