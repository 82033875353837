<!--<aup-loader *ngIf="loading"></aup-loader>-->

<div
  class="overflow-x-hidden h-screen overflow-y-auto bg-[radial-gradient(circle,_#0B42AC,_#062156)] flex flex-col justify-start items-center text-font-white">
  @if(authService.company$ | async; as company) {
  <div class="mt-10 mb-10 w-1/2 max-w-[250px] sm:mt-16">
    <img [src]="company.logo_image" alt="Logo">
  </div>
  }
  <div class="px-4 text-center max-w-[472px] sm:text-center">
    @if (!token) {
    <h1 class="text-3xl sm:text-xl font-bold text-center mb-6">Dokončajte registracijo</h1>
    <p class="mb-10 sm:mb-3 text-lg sm:text-sm">
      Na vaš e-poštni naslov smo poslali sporočilo za potrditev.<br />
      Za dokončanje registracije odprite svojo e-pošto in
      kliknite na povezavo: <br /><span class="font-bold">»Potrjujem svoj e-poštni naslov«.</span> <br />
    </p>
    } @else {
    <h1 class="mb-6 text-center text-3xl font-bold sm:text-xl">{{'confirm_email.title' | translate }}</h1>
    <button (click)="verifyEmail()" class="outlined-button">Aktiviraj</button>
    }
  </div>
</div>