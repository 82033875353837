import { DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';
import Chart, { ActiveElement, ChartEvent, TooltipItem } from 'chart.js/auto';
import { lastValueFrom, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import {
  ApiResponse,
  IChartDataSet,
  IChartState,
  ICustomChartDataset,
} from '../../../models/IUtil.types';
import {
  IWaterMeter,
  IWmGraphData,
  IWmGraphDataItem,
} from '../../../models/IWaterMeters.types';
import { CustomDatePipe } from '../../../pipes/custom-date.pipe';
import { NumberMutatorPipe } from '../../../pipes/number-mutator.pipe';
import { AuthService } from '../../../services/auth.service';
import { WaterMeterService } from '../../../services/water-meter.service';
import { DropdownComponent } from '../dropdowns/dropdown/dropdown.component';
import { IconComponent } from '../icon/icon.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'aup-chart',
  standalone: true,
  imports: [
    NgClass,
    FlowbiteMarkDirective,
    DropdownComponent,
    TranslateModule,
    DecimalPipe,
    IconComponent,
    NumberMutatorPipe,
    LoaderComponent,
    CustomDatePipe,
  ],
  providers: [CustomDatePipe, NumberMutatorPipe, TranslatePipe],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss',
})
export class ChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() wm!: IWaterMeter | null;
  @ViewChild('chart', { static: true }) chartRef:
    | ElementRef<HTMLCanvasElement>
    | undefined;
  data: IWmGraphData = {};
  chartJs!: Chart;
  currentSelectedDataSet!: IChartDataSet;
  months: Array<string> = [];
  tooltipLabels: { counter: string; readout_time: string } = {
    counter: '',
    readout_time: '',
  };
  isChartLoading: boolean = true;
  selectorState: IChartState = {
    state: 'yearly',
    year: '',
    month: '',
    day: '',
    tableData: {
      tableHeaders: [
        'chart.table.readout_time',
        'chart.table.counter',
        'chart.table.consumption',
      ],
      tableBody: [],
    },
  };
  private dataLoaded: boolean = false; // Guard variable

  private _subscriptions: Subscription = new Subscription();
  loading = signal(false);
  constructor(
    private _wmService: WaterMeterService,
    private _translateService: TranslateService,
    private _datePipe: CustomDatePipe,
    private _numberMutatorPipe: NumberMutatorPipe,
    private _translationPipe: TranslatePipe,
    protected authService: AuthService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.chartJs.resize();
  }

  ngOnInit() {
    this.initializeLanguageSettings();
    if (this.wm && this.wm.current) {
      this.fetchChartData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wm'] && changes['wm'].currentValue && !this.dataLoaded) {
      // Only fetch data if it hasn't been loaded yet
      this.fetchChartData();
    }
  }

  async fetchChartData() {
    // Ensure fetchChartData is called only once
    if (!this.dataLoaded && this.wm) {
      this.dataLoaded = true; // Mark data as loaded to prevent future calls

      if (this.chartRef?.nativeElement)
        this.chartRef.nativeElement.style.opacity = '0';
      this.loading.set(true);

      const response = await lastValueFrom(
        this._wmService.getChartDataForCurrentWaterMeter(),
      );
      if ('data' in response) {
        if (this.chartRef?.nativeElement)
          this.chartRef.nativeElement.style.opacity = '100%';
        this.loading.set(false);

        this.data = response.data;
        this.initData(this.data);
        this.isChartLoading = false;
        this.initChart();
      }
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  initializeLanguageSettings() {
    const updateLanguageSettings = (lang: any) => {
      this.months = [
        lang['date.jan'],
        lang['date.feb'],
        lang['date.mar'],
        lang['date.apr'],
        lang['date.may'],
        lang['date.jun'],
        lang['date.jul'],
        lang['date.aug'],
        lang['date.sep'],
        lang['date.oct'],
        lang['date.nov'],
        lang['date.dec'],
      ];
      this.tooltipLabels = {
        counter:
          lang['chart.tooltip.counter'] +
          ' ' +
          this.authService.currentUserUnit(),
        readout_time: lang['chart.tooltip.readout_time'],
      };
    };

    // Initial language settings
    const langSubscription = this._translateService
      .get([
        'date.jan',
        'date.feb',
        'date.mar',
        'date.apr',
        'date.may',
        'date.jun',
        'date.jul',
        'date.aug',
        'date.sep',
        'date.oct',
        'date.nov',
        'date.dec',
        'chart.tooltip.counter',
        'chart.tooltip.readout_time',
      ])
      .subscribe({
        next: (lang) => {
          updateLanguageSettings(lang);
        },
        error: (err) => console.error('Error loading language settings:', err),
      });
    this._subscriptions.add(langSubscription);

    // Update language settings when the language changes
    const langChangeSubscription =
      this._translateService.onLangChange.subscribe({
        next: () => {
          this._translateService
            .get([
              'date.jan',
              'date.feb',
              'date.mar',
              'date.apr',
              'date.may',
              'date.jun',
              'date.jul',
              'date.aug',
              'date.sep',
              'date.oct',
              'date.nov',
              'date.dec',
              'chart.tooltip.counter',
              'chart.tooltip.readout_time',
            ])
            .subscribe({
              next: (lang) => {
                updateLanguageSettings(lang);
                this.updateDataSet(
                  this.selectorState.state,
                  this.selectorState.year,
                  this.selectorState.month,
                  this.selectorState.day,
                );
              },
              error: (err) =>
                console.error(
                  'Error loading language settings on change:',
                  err,
                ),
            });
        },
      });
    this._subscriptions.add(langChangeSubscription);
  }
  initData(data: IWmGraphData) {
    if (data.yearly) {
      this.selectYear();
    } else if (data.monthly) {
      this.selectMonth();
    } else if (data.daily) {
      this.selectDay();
    }
  }

  async setHourlyDataset(
    year: string = '',
    month: string = '',
    day: string = '',
  ) {
    if (this.chartRef?.nativeElement)
      this.chartRef.nativeElement.style.opacity = '0';
    this.loading.set(true); // Initialize loading state to true

    // Check if the year exists in the availableYears, otherwise assign default value
    year =
      year || this.availableYears.includes(year)
        ? year
        : this.availableYears.includes(this.selectorState.year)
          ? this.selectorState.year
          : this.availableYears[0];

    // Check if the month exists in the availableMonths, otherwise assign default value
    month =
      month || this.availableMonths.includes(month)
        ? month
        : this.availableMonths.includes(this.selectorState.month)
          ? this.selectorState.month
          : this.availableMonths[0];

    // Check if the day exists in the availableDays, otherwise assign default value
    day =
      day || this.availableDays.includes(day)
        ? day
        : this.availableDays.includes(this.selectorState.day)
          ? this.selectorState.day
          : this.availableDays[0];

    this.selectorState = {
      state: 'hourly',
      year,
      month,
      day,
      tableData: {
        tableHeaders: this.selectorState.tableData.tableHeaders,
        tableBody: [],
      },
    };
    await this.updateDataSet('hourly', year, month, day);
    this.loading.set(false); // Initialize loading state to true
    if (this.chartRef?.nativeElement)
      this.chartRef.nativeElement.style.opacity = '100%';
  }

  selectYear(year?: string) {
    if (this.loading()) {
      if (this.chartRef?.nativeElement)
        this.chartRef.nativeElement.style.opacity = '100%';
      this.loading.set(false); // Initialize loading state to true
    }
    if (!year) {
      this.selectorState = {
        state: 'yearly',
        year: this.selectorState.year ?? '',
        month: '',
        day: '',
        tableData: {
          tableHeaders: this.selectorState.tableData.tableHeaders,
          tableBody: [],
        },
      };
    } else {
      this.selectorState = {
        ...this.selectorState,
        state: 'monthly',
        year: year,
      };
    }
    this.updateDataSet(
      this.selectorState.state,
      this.selectorState.year,
      this.selectorState.month,
      this.selectorState.day,
    );
  }

  selectMonth(month?: string) {
    if (this.loading()) {
      if (this.chartRef?.nativeElement)
        this.chartRef.nativeElement.style.opacity = '100%';
      this.loading.set(false); // Initialize loading state to true
    }
    if (!month) {
      this.selectorState = {
        state: 'monthly',
        year: this.availableYears[0],
        month: this.selectorState.month ?? '',
        day: '',
        tableData: {
          tableHeaders: this.selectorState.tableData.tableHeaders,
          tableBody: [],
        },
      };
    } else {
      this.selectorState = {
        ...this.selectorState,
        state: 'daily',
        month: month,
        day: '',
      };
    }
    this.updateDataSet(
      this.selectorState.state,
      this.selectorState.year,
      this.selectorState.month,
      this.selectorState.day,
    );
  }

  selectDay(day?: string) {
    if (this.loading()) {
      if (this.chartRef?.nativeElement)
        this.chartRef.nativeElement.style.opacity = '100%';
      this.loading.set(false); // Initialize loading state to true
    }

    if (!day) {
      this.selectorState = {
        state: 'daily',
        year: this.availableYears.includes(this.selectorState.year || '')
          ? this.availableYears.find(
              (year) => year === this.selectorState.year,
            ) ?? this.availableYears[0]
          : this.availableYears[0],
        month: this.availableMonths.includes(this.selectorState.month || '')
          ? this.availableMonths.find(
              (month) => month === this.selectorState.month,
            ) ?? this.availableMonths[0]
          : this.availableMonths[0],
        day: '',
        tableData: {
          tableHeaders: this.selectorState.tableData.tableHeaders,
          tableBody: [],
        },
      };
    } else {
      this.selectorState = { ...this.selectorState, state: 'hourly', day: day };
      if (this.chartRef?.nativeElement)
        this.chartRef.nativeElement.style.opacity = '0';
      this.loading.set(true); // Initialize loading state to true
    }
    this.updateDataSet(
      this.selectorState.state,
      this.selectorState.year,
      this.selectorState.month,
      this.selectorState.day,
    );
  }

  async updateDataSet(
    state: IChartState['state'],
    year?: string,
    month?: string,
    day?: string,
  ) {
    const labelKey = `chart.tooltip.${state}`;
    const translatedLabel =
      (await lastValueFrom(this._translateService.get(labelKey))) ??
      `${this.capitalize(state)} consumption`;
    switch (state) {
      case 'yearly':
        this.setYearlyDataSet(translatedLabel);
        break;
      case 'monthly':
        this.setMonthlyDataSet(translatedLabel, year);
        break;
      case 'daily':
        this.setDailyDataSet(translatedLabel, year, month);
        break;
      case 'hourly':
        await this.setHourlyDataSet(translatedLabel, year, month, day);
        break;
    }

    this.updateChart();
  }

  private capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  private setYearlyDataSet(label: string) {
    const years = Object.values(
      this.data.yearly as { [key: string]: IWmGraphDataItem | null },
    );
    this.selectorState.tableData.tableBody = years;
    this.currentSelectedDataSet = {
      labels: this.availableYears,
      datasets: [
        {
          data: years.map((year) => year?.consumption ?? 0),
          label,
          customDataForTooltips: years.map((year) => year),
        },
      ],
    };
  }

  private setMonthlyDataSet(label: string, year?: string) {
    const months = this.data.monthly
      ? this.data.monthly[year || this.availableYears[0]]
      : [];
    this.selectorState.tableData.tableBody = months;
    this.currentSelectedDataSet = {
      labels: this.months,
      datasets: [
        {
          data: months.map((month) => month?.consumption ?? 0),
          label,
          customDataForTooltips: months.map((month) => month),
        },
      ],
    };
  }

  private setDailyDataSet(label: string, year?: string, month?: string) {
    const days = this.data.daily
      ? this.data.daily[year ?? ''][this.months.indexOf(month ?? '')]
      : [];

    this.selectorState.tableData.tableBody = days;

    this.currentSelectedDataSet = {
      labels: this.data.daily
        ? Object.keys(
            this.data.daily[year ?? ''][this.months.indexOf(month ?? '')],
          ).map((day, index) => index + 1)
        : [],
      datasets: [
        {
          data: days.map((day) => day?.consumption ?? 0),
          label,
          customDataForTooltips: days.map((day) => day),
        },
      ],
    };
  }

  private async setHourlyDataSet(
    label: string,
    year?: string,
    month?: string,
    day?: string,
  ) {
    const response: ApiResponse<Pick<IWmGraphData, 'hourly'>> =
      await lastValueFrom(
        this._wmService.getHourlyDataForCurrentWaterMeter(
          `${this.selectorState.year}-${this.months.indexOf(this.selectorState.month) + 1}-${day ?? this.availableDays[0]}`,
        ),
      );
    if ('data' in response && response.status) {
      if (this.loading()) {
        if (this.chartRef?.nativeElement)
          this.chartRef.nativeElement.style.opacity = '100%';
        this.loading.set(false); // Initialize loading state to true
      }
      this.data.hourly = response.data.hourly;
      const hours = this.data.hourly ? this.data.hourly : [];
      this.selectorState.tableData.tableBody = hours;

      this.currentSelectedDataSet = {
        labels: Array.from(
          { length: 24 },
          (_, i) => `${i.toString().padStart(2, '0')}:00`,
        ),
        datasets: [
          {
            data: hours.map((hour) => hour?.consumption ?? 0),
            label,
            customDataForTooltips: hours.map((hour) => hour),
          },
        ],
      };
    }
  }

  updateChart() {
    this.chartJs.data = this.currentSelectedDataSet;
    this.chartJs.update();
  }

  async initChart() {
    if (this.chartJs) this.chartJs.destroy();
    if (!this.chartRef) return;
    const tooltipTitleCallback = (context: Array<TooltipItem<'bar'>>) => {
      const tooltipItem = context[0];
      const label = tooltipItem.label;

      switch (this.selectorState.state) {
        case 'monthly':
          return `${label}, ${this.selectorState.year}`;
        case 'daily':
          return `${label} ${this.selectorState.month}, ${this.selectorState.year}`;
        case 'hourly':
          return `${label} ${this.selectorState.month}, ${this.selectorState.day}, ${this.selectorState.year}`;
        default:
          return label;
      }
    };
    const tooltipLabelCallback = (
      tooltipItem: TooltipItem<'bar'>,
    ): string[] => {
      const labels: Array<string> = [];

      // Construct the main label
      const datasetLabel = tooltipItem.dataset.label
        ? `${tooltipItem.dataset.label}: `
        : '';
      labels.push(
        `${datasetLabel}${tooltipItem.formattedValue} ${this.authService.currentUserUnit()}`,
      );

      // Add custom tooltip information if available
      const customData = (tooltipItem.dataset as ICustomChartDataset)
        .customDataForTooltips?.[tooltipItem.dataIndex];
      if (customData) {
        const counterLabel = `${this.tooltipLabels.counter}: ${this._numberMutatorPipe.transform(customData.counter)}`;
        const readoutTimeLabel = `${this.tooltipLabels.readout_time}: ${this._datePipe.transform(customData.readout_time_tz, 'medium')}`;
        labels.push(counterLabel, readoutTimeLabel);
      }

      return labels;
    };

    this.chartJs = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: this.currentSelectedDataSet,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: '#1A77EA',
        borderColor: '#EDEDED',
        color: '#AAAAAA',
        onHover: (event: ChartEvent, chartElements: ActiveElement[]) => {
          document.body.style.cursor =
            chartElements.length === 0 || this.selectorState.state === 'hourly'
              ? 'default'
              : 'pointer';
        },
        scales: {
          x: {
            ticks: {
              color: '#AAAAAA',
            },
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              color: '#AAAAAA',
            },
            grid: {
              display: true,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback,
            },
          },
        },
        onClick: (_, elements) => {
          if (elements.length) {
            const element = elements[0];
            const index = element.index;
            switch (this.selectorState.state) {
              case 'yearly':
                this.selectYear(this.availableYears[index]);
                // this.selectYear(this.availableYears[index]);
                break;
              case 'monthly':
                this.selectMonth(this.months[index]);

                // this.setDailyDataset(this.selectorState.year, this.months[index]);
                break;
              case 'daily':
                this.selectDay((index + 1).toString());
                // this.setHourlyDataset(this.selectorState.year, this.selectorState.month, (index + 1).toString());
                break;
            }
          }
        },
      },
    });
  }

  get availableYears() {
    return Object.keys(this.data.yearly || []).filter((year) =>
      this.data.yearly ? this.data.yearly[year] : false,
    );
  }

  get availableMonths() {
    return this.months.filter((_, i) =>
      this.data.daily
        ? this.data.daily[this.selectorState.year || this.availableYears[0]][
            i
          ].some((day) => day && day.consumption > 0)
        : false,
    );
  }

  get availableDays() {
    const yearData = this.data.daily
      ? this.data.daily[this.selectorState.year || this.availableYears[0]]
      : [];
    if (yearData.length === 0) return [];

    const monthData =
      yearData[
        this.months.indexOf(this.selectorState.month || this.availableMonths[0])
      ];
    if (!monthData.some((day) => day)) return [];

    return monthData
      .map((day, i) => (day ? (i + 1).toString() : ''))
      .filter(Boolean);
  }

  private addDecimalMarkup(value: number | string): string | number | null {
    if (typeof value === 'number') {
      return this._numberMutatorPipe.transform(value);
    } else {
      return value;
    }
  }

  exportToExcel() {
    if (!this.selectorState.tableData) return;

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.selectorState.tableData.tableBody
        .filter(Boolean)
        .map((item: Partial<IWmGraphDataItem> | null) => {
          const newItem: Partial<
            Record<string, string | number | null>
          > = {};
          this.selectorState.tableData?.tableHeaders.forEach(
            (header, index) => {
              if (item?.readout_time) {
                delete item.readout_time;
              }

              let key = this._translationPipe.transform(
                header,
              ) as keyof IWmGraphDataItem;

              if (index > 0) {
                key += ` ${this.authService.currentUserUnit()}`;
              }

              newItem[key] = item
                ? this.addDecimalMarkup(Object.values(item)[index])
                : null;
            },
          );
          return newItem;
        }),
    );

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const deviceSn = this.wm?.device_sn || 'unknown-device';
    const day = this.selectorState.day ? `-${this.selectorState.day}` : '';
    const month = this.selectorState.month
      ? `-${this.months.indexOf(this.selectorState.month) + 1}`
      : '';
    const year = this.selectorState.year
      ? `-${this.selectorState.year}`
      : '-all-years';
    const datePart = `${year}${month}${day}`;
    const fileName = `wm-${deviceSn}${datePart}.xlsx`;

    XLSX.writeFile(wb, fileName);
  }
}
